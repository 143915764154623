import React, { useEffect, useState } from "react";
import logo_top from "../assets/logo_top.png";
import label_sale from "../assets/label_sale.png";
import icon1 from "../assets/icon_1.png";
import icon2 from "../assets/icon_2.png";
import icon3 from "../assets/icon_3.png";
import icon4 from "../assets/icon_4.png";
import icon5 from "../assets/icon_5.png";
import icon6 from "../assets/icon_6.png";
import icon7 from "../assets/icon_7.png";
import icon8 from "../assets/icon_8.png";
import icon9 from "../assets/icon_9.png";
import icon10 from "../assets/icon_10.png";
import nav1 from "../assets/nav_1.png";
import nav2 from "../assets/nav_2.png";
import nav3 from "../assets/nav_3.png";
import nav4 from "../assets/nav_4.png";
import banner1 from "../assets/banner_1.png";
import banner2 from "../assets/banner_download.png";
import story from "../assets/ittaly_story.png";
import Footer from "../components/Footer";
import label_promotion from "../assets/promotion-label.png";
import label_brand from "../assets/brand_label.png";
import bosspick from "../assets/bosspick.png";
import sample2 from "../assets/sample2.png";
import sample3 from "../assets/sample3.png";
import sample4 from "../assets/sample4.png";
import sample5 from "../assets/sample5.png";
import { getAllCategories, getHomeDatas } from "../common/apis";
import { currency, percentageCalculation } from "../common/utils";
import { isAndroid, isIOS, isMobile } from "react-device-detect";

export default function RightLayout(): JSX.Element {
  const [data, setData] = useState<any>([]);
  const [promotions, setPromotions] = useState<any>([]);
  const [mainBrands, setMainBrands] = useState<any>([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const { data }: any = await getHomeDatas();

    setData(data);
    setPromotions(data.promotions);
    setMainBrands(data.mainBrands);
  };
  const handleClick = () => {
    if (isAndroid) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.ittaly&hl=ko-KR";
    }

    if (isIOS) {
      window.location.href = "https://apps.apple.com/kr/app/%EC%9E%87%ED%83%9C%EB%A6%AC-ittaly/id6469461890";
    }

    if (!isMobile) {
      window.location.href = "https://apps.apple.com/kr/app/%EC%9E%87%ED%83%9C%EB%A6%AC-ittaly/id6469461890";
    }
  };

  return (
    <div onClick={handleClick}>
      <div className="p-[20px] bg-slate-100 h-[280px] bg-[url('/src/assets/sample1.png')] bg-cover">
        <div className="flex justify-between">
          <div>
            <img src={logo_top} className="w-[72px] h-32px" />
            {/* logo 이미지크기를 div 높이로 지정하세요 */}
          </div>
        </div>
      </div>
      <div className="py-6 px-4 border-b-1 border border-slate-100">
        <p className="text-xl font-bold">
          계절의 변화, <br />
          새로운 시작 VISONA’ 24 S/S
        </p>
        <p className="text-sm text-gray-700 mt-1">따뜻한 봄을 함께할 가방을 만나보세요.</p>

        <div className="flex flex-row  justify-end relative">
          <div className="h-20 w-20 rounded-full bg-gray-200 mt-[-33px]  absolute ">
            <img src={label_sale} />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-5 py-8">
        <div className="text-center pb-3">
          <div className="h-12 flex items-center justify-center">
            <img src={icon1} className="w-[36px] h-[36px]" />
          </div>
          <p className="text-xs text-gray-700">기획전</p>
        </div>
        <div className="text-center pb-3">
          <div className="h-12 flex items-center justify-center">
            <img src={icon2} className="w-[36px] h-[36px]" />
          </div>
          <p className="text-xs text-gray-700">Best</p>
        </div>
        <div className="text-center pb-3">
          <div className="h-12 flex items-center justify-center">
            <img src={icon3} className="w-[36px] h-[36px]" />
          </div>
          <p className="text-xs text-gray-700">Live</p>
        </div>
        <div className="text-center pb-3">
          <div className="h-12 flex items-center justify-center">
            <img src={icon4} className="w-[36px] h-[36px]" />
          </div>
          <p className="text-xs text-gray-700">New</p>
        </div>
        <div className="text-center pb-3">
          <div className="h-12 flex items-center justify-center">
            <img src={icon5} className="w-[36px] h-[36px]" />
          </div>
          <p className="text-xs text-gray-700">이벤트</p>
        </div>
        <div className="text-center pb-3">
          <div className="h-12 flex items-center justify-center">
            <img src={icon6} className="w-[36px] h-[36px]" />
          </div>
          <p className="text-xs text-gray-700">패션잡화</p>
        </div>
        <div className="text-center pb-3">
          <div className="h-12 flex items-center justify-center">
            <img src={icon7} className="w-[36px] h-[36px]" />
          </div>
          <p className="text-xs text-gray-700">뷰티</p>
        </div>
        <div className="text-center pb-3">
          <div className="h-12 flex items-center justify-center">
            <img src={icon8} className="w-[36px] h-[36px]" />
          </div>
          <p className="text-xs text-gray-700">생활용품</p>
        </div>
        <div className="text-center pb-3">
          <div className="h-12 flex items-center justify-center">
            <img src={icon9} className="w-[36px] h-[36px]" />
          </div>
          <p className="text-xs text-gray-700">식품</p>
        </div>
        <div className="text-center pb-3">
          <div className="h-12 flex items-center justify-center">
            <img src={icon10} className="w-[36px] h-[36px]" />
          </div>
          <p className="text-xs text-gray-700">세일</p>
        </div>
      </div>
      <div className="pt-5  border-b-1 ">
        <div className="flex justify-between px-4">
          <p className="text-lg font-bold">잇태리 Live</p>
          <p className="text-xs text-gray-400 pt-1">Live 홈 바로가기</p>
        </div>
      </div>
      <div className="pt-4 border-b-1">
        <iframe className="h-[480px] w-full pl-4" src="https://master.d2rxelpbsiko8x.amplifyapp.com/index.html" />
      </div>

      <div className="relative">
        <div className="absolute top-[-12px] left-[20px]">
          <img src={label_promotion} className="w-[57px] h-auto" />
        </div>

        <div className="z-10">
          <img src={promotions[0]?.imgUrl} className="w-[100%] h-[220px]" />
        </div>
        <div className="relative w-[284px] bg-white mt-[-40px] z-20 rounded-tr-[8px] px-[20px] pt-[24px]">
          <p className="font-bold">{promotions[0]?.title}</p>
          <p className="mt-[8px] text-sm text-gray-800">{promotions[0]?.intro}</p>
          <p className="mt-[16px] text-xs text-gray-400">자세히 보기</p>
        </div>
      </div>

      {/* 매거진 */}
      {/* <div className="px-4 pt-20">
        <div className="snap-start snap-x flex snap-mandatory overflow-x-auto overflow-y-hidden">
          {data?.newProducts?.map((item: any, i: number) => (
            <div key={i} className="mr-[8px]">
              <div className="w-[136px]">
                <div className="bg-slate-200 h-[136px] w-[136px] rounded-md">
                  <img src={item.thumbnail} className="w-[100%] h-[100%] cover rounded-md" />
                </div>

                <p className="text-sm text-gray-600 mt-[4px]">{item.productNameK}</p>
                <p className="text-sm text-pink-400">
                  <span className="mr-[4px]">
                    {item.discounted && percentageCalculation(item.price, item.discounted)}%
                  </span>
                  <span className="font-bold text-gray-700">
                    {item.discounted ? currency(item.discounted) : currency(item.price)}원
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      <div className="px-4 py-20">
        <div className="flex justify-between pb-2">
          <p className="text-lg font-bold">새로 나왔어요</p>
          <p className="text-xs text-gray-400 pt-1">더보기</p>
        </div>

        <div className="snap-start snap-x flex snap-mandatory overflow-x-auto overflow-y-hidden">
          {data?.newProducts?.map((item: any, i: number) => (
            <div key={i} className="mr-[8px]">
              <div className="w-[136px]">
                <div className="bg-slate-200 h-[136px] w-[136px] rounded-md">
                  <img src={item.thumbnail} className="w-[100%] h-[100%] contain rounded-md" />
                </div>

                <p className="text-sm text-gray-600 mt-[4px]">{item.productNameK}</p>
                <p className="text-sm text-pink-400">
                  <span className="mr-[4px]">
                    {item.discounted && percentageCalculation(item.price, item.discounted)}%
                  </span>
                  <span className="font-bold text-gray-700">
                    {item.discounted ? currency(item.discounted) : currency(item.price)}원
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="relative">
        <div className="absolute top-[-12px] left-[20px]">
          <img src={label_brand} className="w-[57px] h-auto" />
        </div>

        <div className="z-10">
          <img src={mainBrands[0]?.imgUrl} className="w-[100%] h-[220px] object-cover" />
        </div>
        <div className="relative w-[284px] bg-white mt-[-40px] z-20 rounded-tr-[8px] px-[20px] pt-[24px]">
          <p className="font-bold">{mainBrands[0]?.brandName}</p>
          <p className="mt-[8px] text-sm text-gray-800">{mainBrands[0]?.desc}</p>
          <p className="mt-[16px] text-xs text-gray-400">자세히 보기</p>
        </div>
      </div>

      <div className="grid grid-cols-2 mx-[10px] mt-5">
        {mainBrands[0]?.relatedProd?.map((brandItem: any, i: number) => (
          <div key={i} className="mx-[10px] mt-[10px]">
            <img src={brandItem.thumbnail} className="w-[100%] h-[210px] contain rounded-md" />
            <p className="text-sm text-gray-600 mt-[6px]">Arcadia 아니타 토트크로스백</p>
            <p className="text-sm text-pink-400 mt-[4px]">
              30% <span className="font-bold text-gray-700">139,000원</span>
            </p>
          </div>
        ))}
      </div>

      <div className="px-4 mt-20">
        <div className="flex justify-between pb-2 ">
          <p className="text-lg font-bold">ittaly Boss Pick!</p>
          <p className="text-xs text-gray-400 pt-1">더보기</p>
        </div>

        <div className="snap-start snap-x flex snap-mandatory overflow-x-auto overflow-y-hidden">
          {data?.bossPickProducts?.map((item: any, i: number) => (
            <div key={i} className="mr-[8px]">
              <div className="w-[136px]">
                <div className="h-[136px] w-[136px] rounded-md ">
                  <div
                    className="bg-cover bg-center w-[136px] h-[136px] p-1"
                    style={{ backgroundImage: `url(${item.thumbnail})` }}
                  >
                    <img src={bosspick} className="w-[50px] h-[24px]" />
                  </div>
                </div>

                <p className="text-sm text-gray-600 mt-[4px]">{item.productNameK}</p>
                <p className="text-sm text-pink-400">
                  <span className="mr-[4px]">
                    {item.discounted && percentageCalculation(item.price, item.discounted)}%
                  </span>
                  <span className="font-bold text-gray-700">
                    {item.discounted ? currency(item.discounted) : currency(item.price)}원
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-20">
        <img src={banner1} className="w-full" />
      </div>
      <div className="mt-20">
        <img src={story} className="w-full" />
      </div>
      <div className="px-4 mt-20">
        <div className="flex justify-between pb-2 ">
          <p className="text-lg font-bold">누구나 좋아하는 인기상품</p>
          <p className="text-xs text-gray-400 pt-1">더보기</p>
        </div>

        <div className="snap-start snap-x flex snap-mandatory overflow-x-auto overflow-y-hidden">
          {data?.popularProducts?.map((item: any, i: number) => (
            <div key={i} className="mr-[8px]">
              <div className="w-[136px]">
                <div className="bg-slate-200 h-[136px] w-[136px] rounded-md ">
                  <img src={item.thumbnail} className="w-[136px] h-[136px] contain rounded-md" />
                </div>

                <p className="text-sm text-gray-600 mt-[4px]">{item.productNameK}</p>
                <p className="text-sm text-pink-400">
                  <span className="mr-[4px]">
                    {item.discounted && percentageCalculation(item.price, item.discounted)}%
                  </span>
                  <span className="font-bold text-gray-700">
                    {item.discounted ? currency(item.discounted) : currency(item.price)}원
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-20">
        <img src={banner2} className="w-full" />
      </div>
      <Footer />

      <div className="fixed w-[480px] bottom-0 bg-white display-pc">
        <div className="grid grid-cols-4 py-3 border-b-1 border border-slate-100">
          <div className="text-center">
            <div className="h-6  flex items-center justify-center">
              <img src={nav1} className="w-[24px] h-[24px]" />
            </div>
            <p className="text-xs text-primary-red">홈</p>
          </div>
          <div className="text-center">
            <div className="h-6  flex items-center justify-center">
              <img src={nav2} className="w-[24px] h-[24px]" />
            </div>
            <p className="text-xs text-gray-700">검색</p>
          </div>
          <div className="text-center">
            <div className="h-6  flex items-center justify-center">
              <img src={nav3} className="w-[24px] h-[24px]" />
            </div>
            <p className="text-xs text-gray-700">라이브</p>
          </div>
          <div className="text-center">
            <div className="h-6  flex items-center justify-center">
              <img src={nav4} className="w-[24px] h-[24px]" />
            </div>
            <p className="text-xs text-gray-700">마이</p>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 bg-white display-m">
        <div className="grid w-screen grid-cols-4 py-3 border-b-1 border border-slate-100">
          <div className="text-center">
            <div className="h-6  flex items-center justify-center">
              <img src={nav1} className="w-[24px] h-[24px]" />
            </div>
            <p className="text-xs text-primary-red">홈</p>
          </div>
          <div className="text-center">
            <div className="h-6  flex items-center justify-center">
              <img src={nav2} className="w-[24px] h-[24px]" />
            </div>
            <p className="text-xs text-gray-700">검색</p>
          </div>
          <div className="text-center">
            <div className="h-6  flex items-center justify-center">
              <img src={nav3} className="w-[24px] h-[24px]" />
            </div>
            <p className="text-xs text-gray-700">라이브</p>
          </div>
          <div className="text-center">
            <div className="h-6  flex items-center justify-center">
              <img src={nav4} className="w-[24px] h-[24px]" />
            </div>
            <p className="text-xs text-gray-700">마이</p>
          </div>
        </div>
      </div>
    </div>
  );
}
