import React, { useEffect, useState } from "react";

import { IMainContext } from "./interface/interface";
import { MainContext } from "./common/context";
import { Route, Routes } from "react-router-dom";
import Layout from "./pages/layout";
import Home from "./pages/home";
import TailwindSample from "./pages/design";

function App(): JSX.Element {
  const _handleStateChange = (key: string, state: any) => {
    setData((prevState: any) => {
      return {
        ...prevState,
        [key]: state,
      };
    });
  };

  const [data, setData] = useState<IMainContext>({
    handleStateChange: _handleStateChange,
    isUser: false,
  });

  return (
    <>
      <MainContext.Provider value={data}>
        <Routes>
          {/* <Route element={<Layout />}> */}

          <Route path="/" index element={<Home />} />
          <Route path="/design" index element={<TailwindSample />} />
          {/* </Route> */}
        </Routes>
      </MainContext.Provider>
    </>
  );
}

export default App;
