import React from "react";

export default function designComponents(): JSX.Element {
  return (
    <div className="pb-10">
      <div className="flex flex-row pb-10">
        <div className="bg-gray-100 basis-1/3">01</div>
        <div className="bg-gray-100 basis-1/3">02</div>
        <div className="bg-gray-100 basis-1/3">03</div>
      </div>

      <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4">
        <div className="h-40 bg-gray-200 rounded-md">
          <p className="text-gray-50">01</p>
        </div>
        <div className="h-40 bg-gray-200 rounded-md">
          <p className="text-gray-50">02</p>
        </div>
        <div className="h-40 bg-gray-200 rounded-md">
          <p className="text-gray-50">03</p>
        </div>
        <div className="h-40 bg-gray-200 rounded-md">
          <p className="text-gray-50">04</p>
        </div>
      </div>

      <div className="grid grid-cols-3 mt-10 h-20 bg-gray-200">
        <div className=""></div>
        <div className="flex items-center cols-start-2">
          <p className="">text items center</p>
        </div>
        <div className=""></div>
      </div>

      <div className="shadow-md w-20 h-20  mt-10 ml-10  items-center justify-center flex">
        <p className="text-blue-900">shadow</p>
      </div>

      <div className="container mx-auto">
        <div className="border-b border-[#000]">
          <p className="text-my-red">container</p>
        </div>
      </div>

      <div className="grid grid-cols-4">
        <div className="px-10">
          <div className="w-full ">
            <p>셀렉트박스</p>
            <div className="flex-row flex">
              <label htmlFor="countries" className="">
                {/* Select an option */}
              </label>
              <select
                id="countries"
                className="appearance-none rounded-sm focus:ring-blue-500 focus:border-blue-500 w-full p-3.5 pl-5 bg-gray-100 border-gray-600 text-gray-500"
              >
                <option selected>사업자 종류</option>
                <option value="co">법인</option>
                <option value="personal">개인</option>
              </select>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="ml-[-30px] mt-4 w-4 h-full"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
            </div>
          </div>
        </div>

        <div className="px-10">
          <div className="w-full ">
            <p>셀렉트박스 + 드롭다운</p>

            <div className="flex-row flex">
              <button
                id="dropdownHoverButton"
                data-dropdown-toggle="dropdownHover"
                data-dropdown-trigger="hover"
                className="text-left rounded-sm focus:ring-blue-500 focus:border-blue-500 w-full p-3.5 pl-5 bg-gray-100 border-gray-600 text-gray-500"
                type="button"
              >
                종류
              </button>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="ml-[-30px] mt-4 w-4 h-full"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
            </div>
            <div
              id="dropdownHover"
              className="z-10 text-left rounded-sm focus:ring-blue-500 focus:border-blue-500 w-full bg-gray-50"
            >
              <ul className=" text-gray-300" aria-labelledby="dropdownHoverButton">
                <li>
                  <a href="#" className="block  p-3.5 pl-5 hover:bg-gray-200  hover:text-gray-500">
                    Dashboard
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block  p-3.5 pl-5 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Settings
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 mt-10">
        <div className="px-10">
          <p>인풋</p>
          <input
            autoComplete="off"
            type="text"
            id="default-input"
            className="bg-gray-100 border border-gray-300 text-gray-500 rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full  p-3.5 pl-5"
          />
        </div>
        <div className="px-10">
          <p>인풋 암호</p>
          <input
            autoComplete="new-password"
            type="password"
            id="default-input"
            className="outline-none outline-2 bg-gray-100 border border-gray-300 text-gray-500 rounded-sm focus:border-red-500 focus:border-2 block w-full p-3.5 pl-5"
          />
        </div>
      </div>

      <p className="px-10 mt-10">버튼</p>
      <div className="grid grid-cols-8 ">
        <div className="px-10">
          <button className="bg-blue-400 hover:bg-blue-500 text-[#efefef] text-sm rounded-md w-full h-11">버튼</button>
        </div>
        <div className="px-10">
          <button className="bg-gray-900 hover:bg-gray-600 text-white text-sm rounded-full px-6 h-10">버튼</button>
        </div>
        <div className="px-10">
          <button className="hover:border-gray-500 border border-gray-900 text-gray-900 hover:text-gray-500 text-sm rounded-full px-6 h-10 ">
            버튼
          </button>
        </div>
        <div className="px-10">
          <button className=" bg-green-300 hover:bg-green-400 text-green-600 border border-green-400 text-xs font-bold rounded-full px-3.5 py-1">
            결제
          </button>
        </div>
        <div className="px-10">
          <button className=" bg-red-200 hover:bg-red-300 text-red-500 border border-red-300 text-xs font-bold rounded-full px-3.5 py-1">
            미결산
          </button>
        </div>
        <div className="px-10">
          <button className="bg-gray-200 w-10 h-10 rounded-full items-center justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          </button>
        </div>
        <div className="px-10">
          <button className="bg-white w-10 h-10 rounded-md border border-gray-200 hover:border-gray-300 items-center justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
            </svg>
          </button>
        </div>
        <div className="px-10">
          <button className="bg-white px-3.5 py-1 rounded-md border-2 shadow-sm border-gray-200 hover:border-gray-300 items-center justify-center flex">
            다운로드
          </button>
        </div>
      </div>

      <div className="grid grid-cols-4 mt-10">
        <div className="px-10">
          <p className="">아이콘 왼쪽 텍스트 오른쪽</p>

          <div className="flex flex-row">
            <button className=" w-8 h-8 rounded-md items-center justify-center flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-800"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z"
                />
              </svg>
            </button>
            <div className=" h-8 items-center size-full flex pl-1">
              <p>Client system</p>
            </div>
          </div>
        </div>
        <div className="px-10">
          <p className="">텍스트 왼쪽 아이콘 오른쪽</p>
          <div className="flex flex-row">
            <div className=" h-8 items-center size-full flex">
              <p>Import exist</p>
            </div>
            <button className=" w-8 h-8 rounded-md items-center justify-center flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-red-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="px-10">
          <p className="">아이콘 양쪽 텍스트 중앙</p>
          <div className="flex flex-row">
            <button className="w-8 h-8 rounded-md items-center justify-center flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-900"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z"
                />
              </svg>
            </button>
            <div className=" h-8 items-center size-full flex pl-2">
              <p>Client system</p>
            </div>
            <button className=" w-8 h-8 rounded-md items-center justify-center flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 mt-10">
        <div className="px-10">
          <p>카드</p>
          <div className="h-48 border border-gray-200 border-3.5 shadow-sm rounded-xl p-6">
            <div className="min-h-full relative">
              <h2 className="font-bold text-xl">결제플랜</h2>
              <p className="text-sm text-gray-500">무료 플랜을 구독하고 10일동안 사용해보세요.</p>
              <div className="absolute bottom-0 right-0 ">
                <button className="bg-gray-900 hover:bg-gray-600 text-white text-sm rounded-lg px-6 h-8">버튼</button>
              </div>
            </div>
          </div>
        </div>
        <div className="px-10">
          <p>카드 그림자</p>
          <div className="h-48 border border-gray-200 border-3.5 shadow-lg rounded-lg p-6">
            <div className="min-h-full relative">
              <button className="bg-purple-200 w-8 h-8 rounded-md items-center justify-center flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 text-purple-700"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="px-10">
          <p>카드 변형</p>
          <div className="h-48 border border-gray-200 border-3.5 shadow-sm rounded-xl p-6">
            <h2 className="font-bold text-xl">선택</h2>
            <p className="text-xs text-gray-500">결제 방법을 선택해주세요</p>

            <div className="border border-gray-200 border-3.5 shadow-sm rounded-xl p-6 mt-3 flex-row flex">
              <div className="h-10 w-12 bg-gray-200 rounded-lg"></div>
              <div className="p-1 w-full">
                <p className="text-xs text-gray-400">무료 플랜을 구독하고 10일동안 사용해보세요.</p>
              </div>
              <div className="h-10 w-10">
                <button className="bg-gray-200 w-10 h-10 rounded-full items-center justify-center flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3">
        <div className="px-10 mt-5">
          <p>프로그레스</p>
          <div className="h-48 w-100 border border-gray-200 border-3.5 shadow-sm rounded-xl p-6">
            <div className="flex justify-between">
              <h2 className="font-bold text-xl">선택</h2>
              <h2 className="text-sm text-gray-500">10,000 / month</h2>
            </div>
            <p className="text-xs text-gray-400">결제 방법을 선택해주세요</p>

            <div className="pt-3 pb-5">
              <div className="bg-gray-200 h-3 rounded-lg">
                <div className={`w-[44%] bg-amber-400 h-3 rounded-lg`} />
              </div>
            </div>
            <button className="float-right  bg-green-300 hover:bg-green-400 text-green-600 border border-green-400 text-xs font-bold rounded-full px-3.5 py-1">
              결제
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3">
        <div className="px-10 mt-5">
          <p>라디오</p>

          <div className="flex items-center mb-4">
            <input
              id="default-radio-1"
              type="radio"
              value=""
              name="default-radio"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
            />
            <label htmlFor="default-radio-1" className="ms-2 text-sm font-medium text-gray-900">
              Default radio
            </label>
          </div>
          <div className="flex items-center">
            <input
              checked
              id="default-radio-2"
              type="radio"
              value=""
              name="default-radio"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
            />
            <label htmlFor="default-radio-2" className="ms-2 text-sm font-medium text-gray-900">
              Checked state
            </label>
          </div>
        </div>
        <div className="px-10 mt-5">
          <p>체크박스</p>

          <div className="flex items-center mb-4">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900">
              Default checkbox
            </label>
          </div>
          <div className="flex items-center mb-4">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900">
              Second checkbox
            </label>
          </div>

          <div className="flex items-center mt-4">
            <input
              disabled
              id="disabled-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 "
            />
            <label htmlFor="disabled-checkbox" className="ms-2 text-sm font-medium text-gray-400 ">
              Disabled checkbox
            </label>
          </div>
        </div>
      </div>

      <div className="px-10 mt-5">
        <p className="mb-2">테이블 *논다크</p>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Product name
                </th>
                <th scope="col" className="px-6 py-3">
                  Color
                </th>
                <th scope="col" className="px-6 py-3">
                  Category
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b hover:bg-gray-50 ">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                  Apple MacBook Pro 17"
                </th>
                <td className="px-6 py-4">Silver</td>
                <td className="px-6 py-4">Laptop</td>
                <td className="px-6 py-4">$2999</td>
                <td className="px-6 py-4 text-right">
                  <a href="#" className="font-medium text-blue-600  hover:underline">
                    Edit
                  </a>
                </td>
              </tr>
              <tr className="bg-white border-b hover:bg-gray-50">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                  Microsoft Surface Pro
                </th>
                <td className="px-6 py-4">White</td>
                <td className="px-6 py-4">Laptop PC</td>
                <td className="px-6 py-4">$1999</td>
                <td className="px-6 py-4 text-right">
                  <a href="#" className="font-medium text-blue-600 hover:underline">
                    Edit
                  </a>
                </td>
              </tr>
              <tr className="bg-white hover:bg-gray-50">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                  Magic Mouse 2
                </th>
                <td className="px-6 py-4">Black</td>
                <td className="px-6 py-4">Accessories</td>
                <td className="px-6 py-4">$99</td>
                <td className="px-6 py-4 text-right">
                  <a href="#" className="font-medium text-blue-600 hover:underline">
                    Edit
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p className="mt-5 mb-2">테이블 *다크</p>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-400">
            <thead className="text-xs uppercase bg-gray-700 text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Product name
                </th>
                <th scope="col" className="px-6 py-3">
                  Color
                </th>
                <th scope="col" className="px-6 py-3">
                  Category
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className=" border-b bg-gray-800 border-gray-700 hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                  Apple MacBook Pro 17"
                </th>
                <td className="px-6 py-4">Silver</td>
                <td className="px-6 py-4">Laptop</td>
                <td className="px-6 py-4">$2999</td>
                <td className="px-6 py-4 text-right">
                  <a href="#" className="font-medium text-blue-500 hover:underline">
                    Edit
                  </a>
                </td>
              </tr>
              <tr className=" border-b bg-gray-800 border-gray-700 hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                  Apple MacBook Pro 17"
                </th>
                <td className="px-6 py-4">Silver</td>
                <td className="px-6 py-4">Laptop</td>
                <td className="px-6 py-4">$2999</td>
                <td className="px-6 py-4 text-right">
                  <a href="#" className="font-medium text-blue-500 hover:underline">
                    Edit
                  </a>
                </td>
              </tr>
              <tr className=" border-b bg-gray-800 border-gray-700 hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                  Apple MacBook Pro 17"
                </th>
                <td className="px-6 py-4">Silver</td>
                <td className="px-6 py-4">Laptop</td>
                <td className="px-6 py-4">$2999</td>
                <td className="px-6 py-4 text-right">
                  <a href="#" className="font-medium text-blue-500 hover:underline">
                    Edit
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p className="mb-2 mt-3">테이블 *홀수짝수</p>

        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Product name
                </th>
                <th scope="col" className="px-6 py-3">
                  Color
                </th>
                <th scope="col" className="px-6 py-3">
                  Category
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="odd:bg-white  even:bg-gray-50  border-b ">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                  Apple MacBook Pro 17"
                </th>
                <td className="px-6 py-4">Silver</td>
                <td className="px-6 py-4">Laptop</td>
                <td className="px-6 py-4">$2999</td>
                <td className="px-6 py-4">
                  <a href="#" className="font-medium text-blue-600  hover:underline">
                    Edit
                  </a>
                </td>
              </tr>
              <tr className="odd:bg-white  even:bg-gray-50 border-b ">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                  Microsoft Surface Pro
                </th>
                <td className="px-6 py-4">White</td>
                <td className="px-6 py-4">Laptop PC</td>
                <td className="px-6 py-4">$1999</td>
                <td className="px-6 py-4">
                  <a href="#" className="font-medium text-blue-600  hover:underline">
                    Edit
                  </a>
                </td>
              </tr>
              <tr className="odd:bg-white  even:bg-gray-50  border-b ">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                  Apple MacBook Pro 17"
                </th>
                <td className="px-6 py-4">Silver</td>
                <td className="px-6 py-4">Laptop</td>
                <td className="px-6 py-4">$2999</td>
                <td className="px-6 py-4">
                  <a href="#" className="font-medium text-blue-600  hover:underline">
                    Edit
                  </a>
                </td>
              </tr>
              <tr className="odd:bg-white  even:bg-gray-50  border-b ">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                  Apple MacBook Pro 17"
                </th>
                <td className="px-6 py-4">Silver</td>
                <td className="px-6 py-4">Laptop</td>
                <td className="px-6 py-4">$2999</td>
                <td className="px-6 py-4">
                  <a href="#" className="font-medium text-blue-600  hover:underline">
                    Edit
                  </a>
                </td>
              </tr>
              <tr className="odd:bg-white  even:bg-gray-50  border-b ">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                  Apple MacBook Pro 17"
                </th>
                <td className="px-6 py-4">Silver</td>
                <td className="px-6 py-4">Laptop</td>
                <td className="px-6 py-4">$2999</td>
                <td className="px-6 py-4">
                  <a href="#" className="font-medium text-blue-600  hover:underline">
                    Edit
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="grid grid-cols-2">
          <div>
            <p className="mb-2 mt-3">테이블 *세로형</p>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase ">
                  <tr>
                    <th scope="col" className="px-6 py-3 bg-gray-50 ">
                      Product name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Color
                    </th>
                    <th scope="col" className="px-6 py-3 bg-gray-50 ">
                      Category
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-gray-200 ">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 ">
                      Apple MacBook Pro 17"
                    </th>
                    <td className="px-6 py-4">Silver</td>
                    <td className="px-6 py-4 bg-gray-50 ">Laptop</td>
                    <td className="px-6 py-4">$2999</td>
                  </tr>
                  <tr className="border-b border-gray-200 ">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 ">
                      Apple MacBook Pro 17"
                    </th>
                    <td className="px-6 py-4">Silver</td>
                    <td className="px-6 py-4 bg-gray-50 ">Laptop</td>
                    <td className="px-6 py-4">$2999</td>
                  </tr>
                  <tr className="border-b border-gray-200 ">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 ">
                      Apple MacBook Pro 17"
                    </th>
                    <td className="px-6 py-4">Silver</td>
                    <td className="px-6 py-4 bg-gray-50 ">Laptop</td>
                    <td className="px-6 py-4">$2999</td>
                  </tr>
                  <tr className="border-b border-gray-200 ">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 ">
                      Apple MacBook Pro 17"
                    </th>
                    <td className="px-6 py-4">Silver</td>
                    <td className="px-6 py-4 bg-gray-50 ">Laptop</td>
                    <td className="px-6 py-4">$2999</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
