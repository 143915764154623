import React from "react";
import LeftLayout from "./leftLayout";
import RightLayout from "./rightLayout";

export default function Home(): JSX.Element {
  return (
    <div className="bg-slate-50 min-h-screen">
      {/* pc */}
      <div className="grid grid-cols-2 display-pc">
        <div className="flex justify-end">
          <div className="w-[480px] min-h-screen">
            <LeftLayout />
          </div>
        </div>
        <div>
          <div className="bg-white w-[480px] h-screen shadow-lg pb-[60px] overflow-y-auto">
            <RightLayout />
          </div>
        </div>
      </div>

      {/* 모바일 */}
      <div className="grid grid-cols-1 display-m">
        <div>
          <div className="bg-white w-screen min-h-screen shadow-lg pb-[60px]">
            <RightLayout />
          </div>
        </div>
      </div>
    </div>
  );
}
