import React from "react";
import ImgLeft from "../assets/main-left.png";

export default function LeftLayout(): JSX.Element {
  return (
    <div className="min-h-screen flex flex-row items-center">
      <div className="w-[340px]">
        <img src={ImgLeft} />
      </div>
    </div>
  );
}
