import React from "react";
import instagram from "../assets/icon_instagram.png";
import apple from "../assets/icon_apple.png";
import google from "../assets/icon_google.png";

const Footer = () => {
  return (
    <div className="pt-[32px] pb-[32px] pl-[20px] pr-[20px] bg-footer-gray">
      <p className="text-lg font-bold">고객센터</p>
      <p className="text-sm text-gray-500 mt-[8px] mb-[8px]">
        월-금요일 10:00 - 17:00 <br /> 점심 12:30 - 13:30 / 주말ㆍ공휴일 제외
      </p>

      <div className="flex">
        <button className="mt-[8px] py-[8px] px-[16px] bg-black rounded-[20px] mr-[8px]">
          <p className="text-xs text-white">FAQ</p>
        </button>
        <button className="mt-[8px] py-[8px] px-[16px] rounded-[20px] border border-slate-300 mr-[8px]">
          <p className="text-xs text-gray-600">실시간 상담</p>
        </button>
        <button className="mt-[8px] py-[8px] px-[16px] rounded-[20px] border border-slate-300">
          <p className="text-xs text-gray-600">1:1 문의</p>
        </button>
      </div>

      <hr className="mt-[32px] mb-[32px]" />

      <div className="flex">
        <p className="mr-[24px] text-xs, text-gray-800 font-medium text-slate-600">개인정보 처리방침</p>
        <p className="mr-[24px] text-xs, text-slate-600 font-light">이용약관</p>
        <p className="mr-[24px] text-xs, text-slate-600 font-light">공지사항</p>
      </div>

      <div className="mt-[32px]">
        <p className="text-xs text-gray-600">사업자 정보</p>
        <p className="text-xs text-gray-400 mt-[4px] leading-5">
          (주)모노글로트홀딩스 대표자: 유양희
          <br />
          사업자등록번호 102-81-44680
          <br />
          통신판매업신고번호: 2016-서울마포-1513
          <br />
          개인정보보호책임자: 유양희(elroelcosmetic@elroel.com)
          <br />
          주소: 서울특별시 마포구 양화로6길 57-18 (서교동)
        </p>
      </div>
      <div className="mt-[32px]">
        <p className="text-xs text-gray-400 mt-[4px] leading-5">Copyright © 2023 monoglot. All Rights Reserved.</p>
      </div>

      <div className="mt-[16px] flex items-center">
        <img src={instagram} className="w-[20px] h-[20px] mr-[16px]" />
        <img src={apple} className="w-[20px] mr-[20px] mt-[-4px]" />
        <img src={google} className="w-[18px]" />
      </div>
    </div>
  );
};

export default Footer;
