// sale 퍼센트 계산
export const percentageCalculation = (originValue: number, calcuValue: number) => {
  const subValue: number = originValue - calcuValue;
  return Math.floor((subValue / originValue) * 100);
};

export const currency = (num: number): string => {
  const n = Math.round(num); // 소수점 자르기
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
