import axios from "axios";
import { URI } from "./config";

// 카테고리 get
export const getAllCategories = async (): Promise<boolean | object> => {
  try {
    const { data }: any = await axios.get(`${URI}/custom/screen/category`);

    return data;
  } catch (error) {
    return false;
  }
};

// // 메인스크린 data get
export const getHomeDatas = async (): Promise<boolean | object> => {
  try {
    const { data }: any = await axios.get(`${URI}/custom/screen/main`);

    return data;
  } catch (error) {
    return false;
  }
};
